<template>
  <v-dialog max-width="500px" v-model="buyDialog">
    <v-card>
      <v-card-title>{{ item.title }}</v-card-title>
      <v-card-text>{{
        $t("vod.detailBuy.buyPackageDesc", {
          title: item.title ? item.title : "",
          price: $options.filters.currency(
            item.price / 100,
            $store.state.session.currencyConfig
          ),
        })
      }}</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="
            sendBuyMovieOrPackage({
              pckgId: item.pckg_id,
              movieId: item.item_id,
            })
          "
          :loading="statusBuyProcess === 'fetching'"
          >{{ $t("vod.detailBuy.yes") }}</v-btn
        >
        <v-btn
          color="primary"
          text
          v-if="item.item_id && item.in_package"
          @click="buyDialog = !buyDialog"
          :to="getPackageRoute()"
          >{{ $t("vod.actions.buyPackage") }}</v-btn
        >
        <v-btn color="primary" text @click="buyDialog = !buyDialog">{{
          $t("vod.detailBuy.no")
        }}</v-btn>
      </v-card-actions>
      <v-alert :value="showError" type="error">{{
        $t("vod.detailBuy.purchaseFailed")
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "BuyDialog",
  data() {
    return {
      showError: false,
    };
  },
  props: ["dialog"],
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
      item: ({ buydialog }) => buydialog.item,
      statusBuyProcess: ({ vod }) => vod.statusBuyProcess,
      buyDialogState: ({ vod }) => vod.buyDialogState,
    }),
    buyDialog: {
      get() {
        return this.buyDialogState;
      },
      set(val) {
        this.showError = false;
        this.setBuyDialog({ state: val });
      },
    },
  },
  methods: {
    ...mapActions("buydialog", ["setDialogData"]),
    ...mapActions("vod", ["buyMovieOrPackage", "setBuyDialog"]),
    sendBuyMovieOrPackage({ pckgId, movieId }) {
      if (pckgId) {
        this.buyMovieOrPackage({
          pckg_id: pckgId.toString(),
          type: "pckg",
        });
      } else {
        this.buyMovieOrPackage({
          movie_id: movieId.toString(),
          type: "movie",
        });
      }
    },
    getPackageRoute() {
      return {
        name: "packagesByMovie",
        params: {
          movieId: this.item.item_id,
        },
      };
    },
  },
  watch: {
    statusBuyProcess(val) {
      this.showError = val === "failure";
    },
  },
};
</script>
