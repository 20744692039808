var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[(_vm.$route.params.movieId)?_c('v-breadcrumbs',{attrs:{"large":"","items":[
      {
        text: _vm.$t('vod.browse'),
        disabled: false,
        to: _vm.getRoute('browse'),
      },
      {
        text: _vm.movieDetails.title,
        disabled: false,
        to: _vm.getRoute('movie'),
      },
      {
        text: _vm.$t('vod.packages'),
        disabled: true,
      },
    ]}}):_vm._e(),(_vm.allPackages.total > 0)?_c('v-layout',{staticClass:"pa-0 ma-0",attrs:{"row":"","wrap":""}},_vm._l((_vm.allPackages.packages),function(item,index){return _c('v-flex',{key:index,attrs:{"xs12":""}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(item.title))]),_c('v-card-text',[_vm._v(_vm._s(item.description))]),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","text":"","color":"primary","to":_vm.getRoute(item),"data-v-step":"packages_0"}},[_vm._v(_vm._s(_vm.$t("vod.details")))]),_c('v-btn',{attrs:{"color":"primary","disabled":item.already_bought,"data-v-step":"packages_1"},on:{"click":function($event){(_vm.buyDialog = true), _vm.setDialogData({ item: item })}}},[_vm._v(_vm._s(_vm._f("currency")((item.price / 100),_vm.$store.state.session.currencyConfig))+" ")])],1)],1)],1)}),1):_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"text-center mt-2"},[_c('v-btn',{attrs:{"text":"","loading":_vm.loading,"disabled":_vm.loading}},[_c('v-icon',{staticClass:"mr-2",on:{"click":_vm.initData}},[_vm._v("mdi-refresh")]),_vm._v(" "+_vm._s(_vm.$t("vod.noDataAvailable"))+" ")],1)],1)],1),_c('buy-dialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }