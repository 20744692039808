<template>
  <v-container fluid grid-list-md>
    <v-breadcrumbs
      v-if="$route.params.movieId"
      large
      :items="[
        {
          text: $t('vod.browse'),
          disabled: false,
          to: getRoute('browse'),
        },
        {
          text: movieDetails.title,
          disabled: false,
          to: getRoute('movie'),
        },
        {
          text: $t('vod.packages'),
          disabled: true,
        },
      ]"
    ></v-breadcrumbs>
    <v-layout v-if="allPackages.total > 0" class="pa-0 ma-0" row wrap>
      <v-flex xs12 v-for="(item, index) in allPackages.packages" :key="index">
        <v-card outlined>
          <v-card-title>{{ item.title }}</v-card-title>
          <v-card-text>{{ item.description }}</v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              text
              color="primary"
              :to="getRoute(item)"
              data-v-step="packages_0"
              >{{ $t("vod.details") }}</v-btn
            >
            <v-btn
              color="primary"
              @click="(buyDialog = true), setDialogData({ item: item })"
              :disabled="item.already_bought"
              data-v-step="packages_1"
              >{{
                (item.price / 100)
                  | currency($store.state.session.currencyConfig)
              }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout v-else row wrap align-center>
      <v-flex class="text-center mt-2">
        <v-btn text :loading="loading" :disabled="loading">
          <v-icon @click="initData" class="mr-2">mdi-refresh</v-icon>
          {{ $t("vod.noDataAvailable") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <buy-dialog></buy-dialog>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BuyDialog from "@/components/vod/BuyDialog";

export default {
  data() {
    return {
      selectedPackage: {
        title: "",
        price: "",
      },
    };
  },
  components: {
    "buy-dialog": BuyDialog,
  },
  computed: {
    ...mapState({
      allPackages: ({ vod }) => vod.allPackages,
      authResult: ({ session }) => session.authResult,
      movieDetails: ({ vod }) => vod.movieDetails,
      statusBuyProcess: ({ vod }) => vod.statusBuyProcess,
      statusPackages: ({ vod }) => vod.statusPackages,
      loading: (state) => state.loading,
      buyDialogState: ({ vod }) => vod.buyDialogState,
    }),
    buyDialog: {
      get() {
        return this.buyDialogState;
      },
      set(val) {
        this.setBuyDialog({ state: val });
      },
    },
  },
  methods: {
    ...mapActions("vod", [
      "getPackages",
      "getMovies",
      "getMovieDetails",
      "setBuyDialog",
    ]),
    ...mapActions("buydialog", ["setDialogData"]),
    ...mapMutations({
      setLoading: "SET_LOADING",
    }),
    initData() {
      this.getPackages({
        movie_id: this.$route.params.movieId,
        only_with_movie_id: !!this.$route.params.movieId,
      });

      if (this.$route.params.movieId) {
        this.getMovieDetails({
          view: "detail",
          item_id: this.$route.params.movieId,
        });
      }
    },
    getRoute(item) {
      if (item === "browse") {
        return {
          name: "browse",
        };
      } else if (item === "movie") {
        return {
          name: "movieDetail",
          params: {
            movieId: this.$route.params.movieId,
          },
        };
      }

      return {
        name: "packageDetail",
        params: {
          pckgId: item.pckg_id,
        },
      };
    },
  },
  created() {
    this.initData();
  },
  watch: {
    statusPackages(val) {
      this.setLoading(val === "fetching");
    },
    statusBuyProcess(val) {
      if (val === "success") {
        this.buyDialog = false;
        this.initData();
      }
    },
  },
};
</script>
